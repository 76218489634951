.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

body {
  background-color: #20262E;
}

.schar-bg {
  will-change: transform;
  position: absolute;
  top: 0;
  left: 0;
  animation: schar-anim 30s ease-in-out infinite;
  width: 100vw;
  opacity: .3;
  pointer-events: none;
}

@keyframes schar-anim {
  0% {
    transform: translate(0%, 10%) scale(1.3);
  }
  20% {
    transform: translate(10%, 20%) scale(1.6);
  }
  40% {
    transform: translate(20%, 20%) scale(2.1);
  }

  60% {
    transform: translate(20%, 20%) scale(1.8);
  }

  80% {
    transform: translate(-20%, 10%) scale(1.7);
  }
  100% {
    transform: translate(0%, -10%) scale(1.3);
  }
}

.schar-container .ant-typography{
  color: #fff !important;
}

.menu-dark {
  background: #20262E;
}

.schar-input {
  background: #333 !important;
}
.schar-input::placeholder {
  color: white;
}

.simg-input::placeholder {
  color: #ddd;
  font-style: italic;
}

.slick-list {
  border-radius: 16px;
}

/* schar styles */

.chat-textarea {
  resize: none !important;
  overflow: hidden;
  padding-block: 15px;
}

.char-textarea::-webkit-scrollbar-track {
  border-radius: 0
}

.char-textarea:::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #c8c8cf;
}


/* end of schar styles */

